<template>
  <header>
    <div class="container">
      <router-link to="/" class="logo">
        <h1>
          <img src="@/assets/logo-small.png" alt="">
        </h1>
      </router-link>

      <div class="menu">
        <nav>
          <router-link to="/">Home</router-link> |
          <router-link to="/regras">Regras</router-link> |
          <router-link to="/fase-atual">Fase Atual</router-link> |
          <router-link to="/quem-somos">Quem Somos</router-link>
        </nav>

        <button class="login" @click="$emit('open-login-modal')" v-if="!userIsLogged">
          <img src="@/assets/ico-reaper-mask.svg" alt="">
        </button>

        <div v-if="userIsLogged" class="login is-logged">
          <p>
            Bem vindo <strong>{{ userGamerTag }}</strong>
          </p>

          <button @click="doLogout()">
            Sair
          </button>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'HeaderVue',

  components: {},

  async mounted() {},

  props: [
    'userIsLogged',
    'userGamerTag'
  ],

  data() {
    return {};
  },

  watch: {},

  computed: {},

  methods: {
    doLogout() {
      this.$cookies.remove('amdsUserGamerTag')
      this.$cookies.remove('amdsUserEmail')
      this.$cookies.remove('amdsUserIsLogged')
      this.$emit('verifyLogin')
    }
  },
}
</script>

<style scoped lang="scss">
header {
  display: flex;
  align-items: center;
  justify-content: center;

  min-height: 110px;

  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 110px;
    left: 50%;
    transform: translateX(-50%);
  }

  &:before {
    top: 0;
    background: url('@/assets/header-bg-a.svg');
    background-repeat: repeat-x;
    background-position: top center;
    z-index: 1;
  }

  &:after {
    top: 20px;
    background: url('@/assets/header-bg-b.svg');
    background-repeat: repeat-x;
    background-position: top center;
    z-index: 0;
  }

  .container {
    z-index: 5;

    .menu {
      display: flex;
      align-items: center;
      justify-content: center;

      nav {
        margin-right: 100px;

        a {
          color: #96B8A0;
          margin: 0 35px;

          position: relative;

          font-family: "Windlass", "Georgia", "serif";
          text-transform: uppercase;
          font-size: 16px;

          &:before {
            content: '';

            position: absolute;
            bottom: -5px;
            left: 0;

            width: 0;
            height: 2px;
            background: url('@/assets/sep.svg');
            background-size: cover;

            transition: all .3s ease;
          }

          &:hover {
            color: #CCCC16;

            &:before {
              width: 35px;
            }
          }

          &.router-link-exact-active {
            color: #CCCC16;

            &:hover {
              color: #FFFFFF;
            }
          }
        }
      }

      .login {
        color: #FFFFFF;
        font-size: 16px;

        &:before {
          content: 'Login';

          color: #FFFFFF;
          background: url('@/assets/black-block.svg');
          background-size: cover;

          position: absolute;
          bottom: 0;
          left: 50%;

          transform: translateX(-50%);
          opacity: 0;
          transition: all .3s ease;
        }

        &:hover {
          transform: scale(1.1) rotate(4deg);

          &:before {
            bottom: -15px;
            opacity: 100%;
          }
        }

        &.is-logged {
          display: flex;
          align-items: flex-end;
          justify-content: center;
          flex-direction: column;

          &:before {
            display: none !important;
          }

          &:hover {
            transform: scale(1) rotate(0deg);

            &:before {
              bottom: 0;
              opacity: 100% !important;
            }
          }

          button {
            color: #CCCD0F;
          }
        }

        p {
          margin-bottom: 0;
        }
      }
    }
  }
}
</style>
