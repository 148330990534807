import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "Home" */ '../views/HomeView.vue')
  },
  {
    path: '/regras',
    name: 'Regras',
    component: () => import(/* webpackChunkName: "Regras" */ '../views/RegrasView.vue')
  },
  {
    path: '/quem-somos',
    name: 'QuemSomos',
    component: () => import(/* webpackChunkName: "QuemSomos" */ '../views/QuemSomosView.vue')
  },
  {
    path: '/fase-atual',
    name: 'FaseAtual',
    component: () => import(/* webpackChunkName: "FaseAtual" */ '../views/FaseAtualView.vue')
  },
  {
    path: '/verify',
    name: 'Verify',
    component: () => import(/* webpackChunkName: "Verify" */ '../views/VerifyView.vue')
  },
  {
    path: '/codigo-um',
    name: 'CodigoUm',
    component: () => import(/* webpackChunkName: "Verify" */ '../views/CodigoUm.vue')
  },
  {
    path: '/codigo-dois',
    name: 'CodigoDois',
    component: () => import(/* webpackChunkName: "Verify" */ '../views/CodigoDois.vue')
  },
  {
    path: '/codigo-final',
    name: 'CodigoFinal',
    component: () => import(/* webpackChunkName: "Verify" */ '../views/CodigoFinal.vue')
  },
  {
    path: '/her-memory',
    name: 'HerMemoryView',
    component: () => import(/* webpackChunkName: "Verify" */ '../views/HerMemory/HerMemoryView.vue')
  },
  {
    path: '/her-memory/reaper-elevator-entrance',
    name: 'ReaperElevatorEntranceView',
    component: () => import(/* webpackChunkName: "Verify" */ '../views/HerMemory/ReaperElevatorEntranceView.vue')
  },
  {
    path: '/her-memory/reaper-elevator',
    name: 'ReaperElevatorView',
    component: () => import(/* webpackChunkName: "Verify" */ '../views/HerMemory/ReaperElevatorView.vue')
  },
  {
    path: '/her-memory/reaper-down',
    name: 'ReaperDownView',
    component: () => import(/* webpackChunkName: "Verify" */ '../views/HerMemory/ReaperDownView.vue')
  },
  {
    path: '/her-memory/reaper-down-two',
    name: 'ReaperDownTwoView',
    component: () => import(/* webpackChunkName: "Verify" */ '../views/HerMemory/ReaperDownTwoView.vue')
  },
  {
    path: '/her-memory/reaper-down-fj',
    name: 'ReaperDownFirstJumpView',
    component: () => import(/* webpackChunkName: "Verify" */ '../views/HerMemory/ReaperDownFirstJumpView.vue')
  },
  {
    path: '/her-memory/reaper-down-sj',
    name: 'ReaperDownSecondJumpView',
    component: () => import(/* webpackChunkName: "Verify" */ '../views/HerMemory/ReaperDownSecondJumpView.vue')
  },
  {
    path: '/her-memory/reaper-down-tt',
    name: 'ReaperDownTtView',
    component: () => import(/* webpackChunkName: "Verify" */ '../views/HerMemory/ReaperDownTtView.vue')
  },
  {
    path: '/her-memory/reaper-down-nine',
    name: 'ReaperDownNineView',
    component: () => import(/* webpackChunkName: "Verify" */ '../views/HerMemory/ReaperDownNineView.vue')
  },
  {
    path: '/her-memory/reaper-down-nine-esq',
    name: 'ReaperDownNineEsqView',
    component: () => import(/* webpackChunkName: "Verify" */ '../views/HerMemory/ReaperDownNineEsqView.vue')
  },
  {
    path: '/her-memory/reaper-down-nine-ret',
    name: 'ReaperDownNineRetView',
    component: () => import(/* webpackChunkName: "Verify" */ '../views/HerMemory/ReaperDownNineRetView.vue')
  },
  {
    path: '/her-memory/reaper-down-nine-ret-mine',
    name: 'ReaperDownNineRetMineView',
    component: () => import(/* webpackChunkName: "Verify" */ '../views/HerMemory/ReaperDownNineRetMineView.vue')
  },
  {
    path: '/her-memory/reaper-down-nine-dir',
    name: 'ReaperDownNineDirView',
    component: () => import(/* webpackChunkName: "Verify" */ '../views/HerMemory/ReaperDownNineDirView.vue')
  },
  {
    path: '/her-memory/reaper-down-door',
    name: 'ReaperDownDoorView',
    component: () => import(/* webpackChunkName: "Verify" */ '../views/HerMemory/ReaperDownDoorView.vue')
  },
  {
    path: '/her-memory/reaper-door-first',
    name: 'ReaperDownDoorFirstView',
    component: () => import(/* webpackChunkName: "Verify" */ '../views/HerMemory/ReaperDownDoorFirstView.vue')
  },
  {
    path: '/her-memory/reaper-door-second',
    name: 'ReaperDownDoorSecondView',
    component: () => import(/* webpackChunkName: "Verify" */ '../views/HerMemory/ReaperDownDoorSecondView.vue')
  },
  {
    path: '/her-memory/reaper-door-third',
    name: 'ReaperDownDoorThirdView',
    component: () => import(/* webpackChunkName: "Verify" */ '../views/HerMemory/ReaperDownDoorThirdView.vue')
  },
  {
    path: '/her-memory/reaper-door-mermaid',
    name: 'ReaperDownDoorMermaidView',
    component: () => import(/* webpackChunkName: "Verify" */ '../views/HerMemory/ReaperDownDoorMermaidView.vue')
  },
  {
    path: '/her-memory/reaper-escape',
    name: 'ReaperDownEscapeView',
    component: () => import(/* webpackChunkName: "Verify" */ '../views/HerMemory/ReaperDownEscapeView.vue')
  },
  {
    path: '/her-memory/reaper-ship',
    name: 'ReaperDownShipView',
    component: () => import(/* webpackChunkName: "Verify" */ '../views/HerMemory/ReaperDownShipView.vue')
  },
  {
    path: '/her-memory/ramsey',
    name: 'ReaperRamseyView',
    component: () => import(/* webpackChunkName: "Verify" */ '../views/HerMemory/ReaperRamseyView.vue')
  },
  {
    path: '/her-memory/ramsey-fight',
    name: 'RamseyFight',
    component: () => import(/* webpackChunkName: "Verify" */ '../views/HerMemory/RamseyFight.vue')
  },
  {
    path: '/her-memory/ramsey-f',
    name: 'ReaperRamseyFView',
    component: () => import(/* webpackChunkName: "Verify" */ '../views/HerMemory/ReaperRamseyFView.vue')
  },
  {
    path: '/her-memory/kk-f',
    name: 'KKFView',
    component: () => import(/* webpackChunkName: "Verify" */ '../views/HerMemory/KKFView.vue')
  },
  {
    path: '/her-memory/letter-f',
    name: 'LetterFView',
    component: () => import(/* webpackChunkName: "Verify" */ '../views/HerMemory/LetterFView.vue')
  },
  {
    path: '/disc',
    name: 'Disc',
    component: () => import(/* webpackChunkName: "Verify" */ '../views/Disc.vue')
  },
]

const router = new VueRouter({
  routes
})

export default router
