<!-- Adicionar o "ultimo login"-->
<!-- Mensagem de cookies -->

<template>
  <div id="app" :class="isPageLoading ? 'loading-page' : ''">
    <div class="desk-only">
      <HeaderVue @open-login-modal="openLoginModal" :userIsLogged="userIsLogged" :userGamerTag="userGamerTag"
        @verifyLogin="verifyLogin()" class="header-only"/>

      <router-view @open-login-modal="openLoginModal" @open-trailer-modal="openTrailerModal" :userIsLogged="userIsLogged"
        class="header-only" />

      <FooterVue class="header-only" />

      <Transition name="bounce">
        <div class="modal-wrapper" v-if="isLoginModalOpen && !userIsLogged" @click.self="isLoginModalOpen = false">
          <div class="modal">
            <div class="header">
              <p v-if="isLoginOrSignup == 'login'">Login</p>
              <p v-if="isLoginOrSignup == 'signup'">Cadastro</p>

              <button class="close" @click="isLoginModalOpen = false"></button>
            </div>

            <div class="content">
              <div class="loading-wrapper" :class="isLoading ? 'active' : ''">
                <img src="@/assets/wheel.svg" alt="">
              </div>

              <div class="login-signup-wrapper" :class="[isLoginOrSignup, isLoading ? 'loading' : '']">
                <div class="login" v-if="!verificationSent">
                  <label class="text-input">
                    <input placeholder="E-Mail" type="text" v-model="userEmail" maxlength="113">
                  </label>

                  <label class="text-input">
                    <input placeholder="Senha" type="password" v-model="userPass">
                  </label>

                  <button class="btn" @click="doLogin()">
                    <p>
                      Entrar
                    </p>
                  </button>

                  <p class="signup-text">
                    Ainda não possui cadastro?

                    <a @click="isLoginOrSignup = 'signup'">
                      Cadastre-se!
                    </a>
                  </p>
                </div>

                <div class="signup" v-if="!verificationSent">
                  <label class="text-input">
                    <input placeholder="E-Mail" type="text" v-model="userEmail" maxlength="113">
                  </label>

                  <label class="text-input">
                    <input placeholder="GamerTag" type="text" v-model="userGamerTag" maxlength="12">
                  </label>

                  <label class="text-input">
                    <input placeholder="Usuário do Discord" type="text" v-model="userDiscord" maxlength="32">
                  </label>

                  <label class="text-input password">
                    <input placeholder="Senha" :type="passInputType" v-model="userPass">
                    <button @click="changePassInputType()">
                      <img src="@/assets/ico-eye.svg" alt="" v-if="passInputType == 'text'">
                      <img src="@/assets/ico-eye-closed.svg" alt="" v-if="passInputType == 'password'">
                    </button>
                  </label>

                  <label class="text-input password">
                    <input placeholder="Repita a Senha" :type="passInputType" v-model="userRepeatPass">
                    <button @click="changePassInputType()">
                      <img src="@/assets/ico-eye.svg" alt="" v-if="passInputType == 'text'">
                      <img src="@/assets/ico-eye-closed.svg" alt="" v-if="passInputType == 'password'">
                    </button>
                  </label>

                  <button class="btn" @click="doSignup()">
                    <p>
                      Cadastrar
                    </p>
                  </button>

                  <p class="signup-text">
                    Já possui cadastro?

                    <a @click="isLoginOrSignup = 'login'">
                      Faça login!
                    </a>
                  </p>
                </div>

                <div v-if="verificationSent">
                  <p class="signup-text">
                    E-Mail de verificação enviado para <strong>{{ userEmail }}</strong> <br />
                    Acesse seu E-Mail para verificar sua conta.
                  </p>

                  <p>
                    Caso não encontre, verifique sua lixeira ou caixa de spam!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Transition>

      <Transition name="bounce">
        <div class="modal-wrapper" v-if="isTrailerModalOpen" @click.self="isTrailerModalOpen = false">
          <div class="modal">
            <div class="header">
              <p>Indo para o Youtube</p>

              <button class="close" @click="isTrailerModalOpen = false"></button>
            </div>

            <div class="content">
              <p>
                Você está saindo deste site para abrir o trailer no YouTube. <br />
                Deseja continuar?
              </p>

              <div class="btn-wrapper">
                <a href="https://youtu.be/OC1ViGlBexY" target="_blank">SIM!</a>
                <a href="#" @click.prevent="isTrailerModalOpen = false">Não!</a>
              </div>
            </div>
          </div>
        </div>
      </Transition>

      <Transition name="bounce">
        <div class="toast-wrapper" v-if="openToast">
          <div class="toast" :class="toastType">
            <p>
              {{ toastText }}
            </p>
          </div>
        </div>
      </Transition>

      <Transition name="bounce">
        <div class="modal-wrapper kc" v-if="isKcModalOpen">
          <div class="modal">
            <div class="content">
              <!-- <video autoplay loop>
                <source src="@/assets/videos/auauau.mp4" type="video/mp4">
                :(
              </video> -->

              <iframe
                class="video-resp"
                src="https://www.youtube.com/embed/videoseries?si=1UcS2i3kBt23NkLa&amp;list=PLCAl05PtHumw66fL7iOBL8OyfjABpd-95&controls=0&showinfo=0&rel=0&autoplay=1&loop=1&mute=1?modestbranding=1&showinfo=0"
                title=""
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              ></iframe>
            </div>
          </div>
        </div>
      </Transition>
    </div>

    <div class="mobile-only">
      <h1>
        Este site ainda não possui versão para celular pois o programador é preguiçoso igual a Rare consertando o hitreg...
        :)
      </h1>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HeaderVue from '@/components/HeaderVue.vue'
import FooterVue from '@/components/FooterVue.vue'

import axios from 'axios'

export default {
  name: 'App',

  components: {
    HeaderVue,
    FooterVue
  },

  // beforeCreate() {
  //   var check = this.$cookies.get('fd1597e51b87431c5b9f71add25a8bd6')
  //   if (!check || check != 'b326b5062b2f0e69046810717534cb09') window.location.href = 'https://asmaresdesegredos.com.br/breve/'
  // },

  async beforeMount() {
    this.isPageLoading = true
  },

  async mounted() {
    this.isLoginModalOpen = false
    this.isTrailerModalOpen = false

    await this.verifyHost()
    await this.runKonami()

    this.isPageLoading = false
  },

  data() {
    return {
      isPageLoading: true,
      isDevOrProd: '',

      isLoginModalOpen: false,
      isTrailerModalOpen: false,
      isKcModalOpen: false,

      userIp: '',
      userIpData: {},

      userEmail: '',
      userGamerTag: '',
      userDiscord: '',
      userPass: '',
      userRepeatPass: '',
      userIsLogged: false,

      passInputType: 'password',

      isLoginOrSignup: 'login',
      isLoading: false,

      verificationSent: false,

      showToast: false,
      toastType: '',
      toastText: '',

      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
    };
  },

  watch: {
    userDiscord() {
      this.userDiscord = this.userDiscord.toLowerCase()
    }
  },

  computed: {},

  methods: {
    verifyHost() {
      const url = window.location.href

      if (url.startsWith('http://asmaresdesegredos.com.br')) this.isDevOrProd = 'prod'
      if (url.startsWith('http://localhost')) this.isDevOrProd = 'dev'

      this.userIsLogged = this.$cookies.get('amdsUserIsLogged')
      if (this.userIsLogged) this.userGamerTag = this.$cookies.get('amdsUserGamerTag')
    },

    openLoginModal() {
      this.$cookies.remove('amdsUserGamerTag')
      this.$cookies.remove('amdsUserEmail')
      this.$cookies.remove('amdsUserIsLogged')
      this.userIsLogged = false

      this.isLoginModalOpen = true
    },

    openTrailerModal() {
      this.isTrailerModalOpen = true
    },

    openToast(type, text) {
      this.showToast = false
      this.toastType = ''
      this.toastText = ''

      this.showToast = true
      this.toastType = type
      this.toastText = text

      setTimeout(() => {
        this.showToast = false
      }, '10000')
    },

    changePassInputType() {
      if (this.passInputType == 'password') return this.passInputType = 'text';
      if (this.passInputType == 'text') return this.passInputType = 'password';
    },

    async doLogin() {
      if (this.userEmail == '') {
        this.openToast('error', 'Você precisa preencher o E-Mail!')
        return;
      }

      if (!this.reg.test(this.userEmail)) {
        this.openToast('error', 'Você precisa preencher um E-Mail válido!')
        return;
      }

      if (this.userPass == '') {
        this.openToast('error', 'Você precisa preencher a Senha!')
        return;
      }

      var body = {
        "userEmail": this.userEmail,
        "userPassword": this.md5(this.userPass),
      }

      await axios
        .post(`https://nof1kkipnb.execute-api.us-east-1.amazonaws.com/${this.isDevOrProd}`, body)
        .then(response => {
          this.userGamerTag = response.data.gamerTag

          if (response.data.statusCode == 401) {
            this.openToast('error', response.data.returnMessage)
            return;
          }

          if (response.data.statusCode == 200) {
            this.userIsLogged = true
            this.isLoginModalOpen = false
            this.isTrailerModalOpen = false

            this.$cookies.set('amdsUserGamerTag', this.userGamerTag)
            this.$cookies.set('amdsUserEmail', this.userEmail)
            this.$cookies.set('amdsUserIsLogged', this.userIsLogged)

            return;
          }
        })
        .catch(error => {
          console.log(error)
          this.openToast('error', 'Ocorreu um erro ao fazer Login. Entre em contato com o administrador ou tente novamente mais tarde!')
          return;
        })
    },

    async getIp() {
      await axios
        .get(`https://api.ipify.org`)
        .then(response => {
          this.userIp = response.data
          return true;
        })
        .catch(error => {
          console.log(error)
          this.openToast('error', 'Ocorreu um erro ao verificar sua localização. Entre em contato com o administrador ou tente novamente mais tarde.')
          this.isLoading = false
          return false;
        })
    },

    async getLocation() {
      await axios
        // .get(`http://ip-api.com/json/${this.userIp}?fields=status,message,continent,continentCode,country,countryCode,region,regionName,city,zip,lat,lon,timezone,offset,currency,isp,org,as,asname,reverse,mobile,proxy,hosting,query`)
        .get(`https://api.country.is/${this.userIp}`)
        .then(response => {
          this.userIpData = response.data
          return true;
        })
        .catch(error => {
          this.openToast('error', 'Não conseguimos verificar sua localização. Entre em contato com o administrador ou tente novamente mais tarde!')
          this.isLoading = false
          return false;
        })
    },

    async doSignup() {
      this.isLoading = true

      await this.getIp()

      if (!this.getIp()) return;

      await this.getLocation()

      if (!this.getLocation()) return;

      var country = String(this.userIpData.country)

      if (!this.reg.test(this.userEmail)) {
        this.openToast('error', 'Você precisa preencher um E-Mail válido!')
        this.isLoading = false
        return;
      }

      if (this.userGamerTag == '') {
        this.openToast('error', 'Você precisa preencher a Gamertag!')
        this.isLoading = false
        return;
      }

      if (this.userDiscord == '') {
        this.openToast('error', 'Você precisa preencher o seu Discord!')
        this.isLoading = false
        return;
      }

      if (this.userPass == '') {
        this.openToast('error', 'Você precisa preencher a Senha!')
        this.isLoading = false
        return;
      }

      if (this.userRepeatPass == '') {
        this.openToast('error', 'Você precisa repetir a Senha!')
        this.isLoading = false
        return;
      }

      if (this.md5(this.userPass) != this.md5(this.userRepeatPass)) {
        this.openToast('error', 'As senhas não coincidem!')
        this.isLoading = false
        return;
      }

      if (this.userIp == '') {
        this.openToast('error', 'Ocorreu um erro ao fazer seu cadastro. Entre em contato com o administrador ou tente novamente mais tarde!')
        this.isLoading = false
        return;
      }

      if (country != 'BR') {
        this.openToast('error', 'Você precisa residir no Brasil para participar!')
        this.isLoading = false
        return;
      }

      var body = {
        "userEmail": this.userEmail,
        "userGamerTag": this.userGamerTag,
        "userDiscord": this.userDiscord,
        "userPassword": this.md5(this.userPass),
        "userIp": this.userIp,
        "userCountry": country
      }

      await axios
        .post(`https://wh5ec1zej3.execute-api.us-east-1.amazonaws.com/${this.isDevOrProd}`, body)
        .then(response => {
          if (response.data.statusCode == 500) {
            this.openToast('error', response.data.returnMessage)
            this.isLoading = false
            return;
          }

          if (response.data.statusCode == 401) {
            this.openToast('error', response.data.returnMessage)
            this.isLoading = false
            return;
          }

          if (response.data.statusCode == 200) {
            this.sendVerifyMail()
            return;
          }

        })
        .catch(error => {
          console.log(error)
          this.openToast('error', 'Ocorreu um erro ao fazer seu cadastro. Entre em contato com o administrador ou tente novamente mais tarde!')
          this.isLoading = false
          return;
        })
    },

    verifyLogin() {
      this.userIsLogged = false
    },

    async sendVerifyMail() {
      this.verificationSent = false

      var body = {
        "userGamerTag": this.userGamerTag,
        "userEmail": this.userEmail
      }

      await axios
        .post(`https://n7zuoul7ok.execute-api.us-east-1.amazonaws.com/${this.isDevOrProd}`, body)
        .then(response => {
          this.openToast('success', response.data.returnMessage)
          this.verificationSent = true
          this.isLoading = false
        })
        .catch(error => {
          console.log(error)
          this.openToast('error', 'Ocorreu um erro ao enviar o E-Mail de verificação. Entre em contato com o administrador ou tente novamente mais tarde!')
          this.isLoading = false
        })
    },

    md5(string) {
      function RotateLeft(lValue, iShiftBits) {
        return (lValue << iShiftBits) | (lValue >>> (32 - iShiftBits));
      }

      function AddUnsigned(lX, lY) {
        var lX4, lY4, lX8, lY8, lResult;
        lX8 = (lX & 0x80000000);
        lY8 = (lY & 0x80000000);
        lX4 = (lX & 0x40000000);
        lY4 = (lY & 0x40000000);
        lResult = (lX & 0x3FFFFFFF) + (lY & 0x3FFFFFFF);
        if (lX4 & lY4) {
          return (lResult ^ 0x80000000 ^ lX8 ^ lY8);
        }
        if (lX4 | lY4) {
          if (lResult & 0x40000000) {
            return (lResult ^ 0xC0000000 ^ lX8 ^ lY8);
          } else {
            return (lResult ^ 0x40000000 ^ lX8 ^ lY8);
          }
        } else {
          return (lResult ^ lX8 ^ lY8);
        }
      }

      function F(x, y, z) { return (x & y) | ((~x) & z); }
      function G(x, y, z) { return (x & z) | (y & (~z)); }
      function H(x, y, z) { return (x ^ y ^ z); }
      function I(x, y, z) { return (y ^ (x | (~z))); }

      function FF(a, b, c, d, x, s, ac) {
        a = AddUnsigned(a, AddUnsigned(AddUnsigned(F(b, c, d), x), ac));
        return AddUnsigned(RotateLeft(a, s), b);
      };

      function GG(a, b, c, d, x, s, ac) {
        a = AddUnsigned(a, AddUnsigned(AddUnsigned(G(b, c, d), x), ac));
        return AddUnsigned(RotateLeft(a, s), b);
      };

      function HH(a, b, c, d, x, s, ac) {
        a = AddUnsigned(a, AddUnsigned(AddUnsigned(H(b, c, d), x), ac));
        return AddUnsigned(RotateLeft(a, s), b);
      };

      function II(a, b, c, d, x, s, ac) {
        a = AddUnsigned(a, AddUnsigned(AddUnsigned(I(b, c, d), x), ac));
        return AddUnsigned(RotateLeft(a, s), b);
      };

      function ConvertToWordArray(string) {
        var lWordCount;
        var lMessageLength = string.length;
        var lNumberOfWords_temp1 = lMessageLength + 8;
        var lNumberOfWords_temp2 = (lNumberOfWords_temp1 - (lNumberOfWords_temp1 % 64)) / 64;
        var lNumberOfWords = (lNumberOfWords_temp2 + 1) * 16;
        var lWordArray = Array(lNumberOfWords - 1);
        var lBytePosition = 0;
        var lByteCount = 0;
        while (lByteCount < lMessageLength) {
          lWordCount = (lByteCount - (lByteCount % 4)) / 4;
          lBytePosition = (lByteCount % 4) * 8;
          lWordArray[lWordCount] = (lWordArray[lWordCount] | (string.charCodeAt(lByteCount) << lBytePosition));
          lByteCount++;
        }
        lWordCount = (lByteCount - (lByteCount % 4)) / 4;
        lBytePosition = (lByteCount % 4) * 8;
        lWordArray[lWordCount] = lWordArray[lWordCount] | (0x80 << lBytePosition);
        lWordArray[lNumberOfWords - 2] = lMessageLength << 3;
        lWordArray[lNumberOfWords - 1] = lMessageLength >>> 29;
        return lWordArray;
      };

      function WordToHex(lValue) {
        var WordToHexValue = '', WordToHexValue_temp = '', lByte, lCount;
        for (lCount = 0; lCount <= 3; lCount++) {
          lByte = (lValue >>> (lCount * 8)) & 255;
          WordToHexValue_temp = '0' + lByte.toString(16);
          WordToHexValue = WordToHexValue + WordToHexValue_temp.substr(WordToHexValue_temp.length - 2, 2);
        }
        return WordToHexValue;
      };

      function Utf8Encode(string) {
        string = string.replace(/\r\n/g, '\n');
        var utftext = '';

        for (var n = 0; n < string.length; n++) {
          var c = string.charCodeAt(n);

          if (c < 128) {
            utftext += String.fromCharCode(c);
          }
          else if ((c > 127) && (c < 2048)) {
            utftext += String.fromCharCode((c >> 6) | 192);
            utftext += String.fromCharCode((c & 63) | 128);
          }
          else {
            utftext += String.fromCharCode((c >> 12) | 224);
            utftext += String.fromCharCode(((c >> 6) & 63) | 128);
            utftext += String.fromCharCode((c & 63) | 128);
          }
        }

        return utftext;
      };

      var x = Array();
      var k, AA, BB, CC, DD, a, b, c, d;
      var S11 = 7, S12 = 12, S13 = 17, S14 = 22;
      var S21 = 5, S22 = 9, S23 = 14, S24 = 20;
      var S31 = 4, S32 = 11, S33 = 16, S34 = 23;
      var S41 = 6, S42 = 10, S43 = 15, S44 = 21;

      string = Utf8Encode(string);

      x = ConvertToWordArray(string);

      a = 0x67452301; b = 0xEFCDAB89; c = 0x98BADCFE; d = 0x10325476;

      for (k = 0; k < x.length; k += 16) {
        AA = a; BB = b; CC = c; DD = d;
        a = FF(a, b, c, d, x[k + 0], S11, 0xD76AA478);
        d = FF(d, a, b, c, x[k + 1], S12, 0xE8C7B756);
        c = FF(c, d, a, b, x[k + 2], S13, 0x242070DB);
        b = FF(b, c, d, a, x[k + 3], S14, 0xC1BDCEEE);
        a = FF(a, b, c, d, x[k + 4], S11, 0xF57C0FAF);
        d = FF(d, a, b, c, x[k + 5], S12, 0x4787C62A);
        c = FF(c, d, a, b, x[k + 6], S13, 0xA8304613);
        b = FF(b, c, d, a, x[k + 7], S14, 0xFD469501);
        a = FF(a, b, c, d, x[k + 8], S11, 0x698098D8);
        d = FF(d, a, b, c, x[k + 9], S12, 0x8B44F7AF);
        c = FF(c, d, a, b, x[k + 10], S13, 0xFFFF5BB1);
        b = FF(b, c, d, a, x[k + 11], S14, 0x895CD7BE);
        a = FF(a, b, c, d, x[k + 12], S11, 0x6B901122);
        d = FF(d, a, b, c, x[k + 13], S12, 0xFD987193);
        c = FF(c, d, a, b, x[k + 14], S13, 0xA679438E);
        b = FF(b, c, d, a, x[k + 15], S14, 0x49B40821);
        a = GG(a, b, c, d, x[k + 1], S21, 0xF61E2562);
        d = GG(d, a, b, c, x[k + 6], S22, 0xC040B340);
        c = GG(c, d, a, b, x[k + 11], S23, 0x265E5A51);
        b = GG(b, c, d, a, x[k + 0], S24, 0xE9B6C7AA);
        a = GG(a, b, c, d, x[k + 5], S21, 0xD62F105D);
        d = GG(d, a, b, c, x[k + 10], S22, 0x2441453);
        c = GG(c, d, a, b, x[k + 15], S23, 0xD8A1E681);
        b = GG(b, c, d, a, x[k + 4], S24, 0xE7D3FBC8);
        a = GG(a, b, c, d, x[k + 9], S21, 0x21E1CDE6);
        d = GG(d, a, b, c, x[k + 14], S22, 0xC33707D6);
        c = GG(c, d, a, b, x[k + 3], S23, 0xF4D50D87);
        b = GG(b, c, d, a, x[k + 8], S24, 0x455A14ED);
        a = GG(a, b, c, d, x[k + 13], S21, 0xA9E3E905);
        d = GG(d, a, b, c, x[k + 2], S22, 0xFCEFA3F8);
        c = GG(c, d, a, b, x[k + 7], S23, 0x676F02D9);
        b = GG(b, c, d, a, x[k + 12], S24, 0x8D2A4C8A);
        a = HH(a, b, c, d, x[k + 5], S31, 0xFFFA3942);
        d = HH(d, a, b, c, x[k + 8], S32, 0x8771F681);
        c = HH(c, d, a, b, x[k + 11], S33, 0x6D9D6122);
        b = HH(b, c, d, a, x[k + 14], S34, 0xFDE5380C);
        a = HH(a, b, c, d, x[k + 1], S31, 0xA4BEEA44);
        d = HH(d, a, b, c, x[k + 4], S32, 0x4BDECFA9);
        c = HH(c, d, a, b, x[k + 7], S33, 0xF6BB4B60);
        b = HH(b, c, d, a, x[k + 10], S34, 0xBEBFBC70);
        a = HH(a, b, c, d, x[k + 13], S31, 0x289B7EC6);
        d = HH(d, a, b, c, x[k + 0], S32, 0xEAA127FA);
        c = HH(c, d, a, b, x[k + 3], S33, 0xD4EF3085);
        b = HH(b, c, d, a, x[k + 6], S34, 0x4881D05);
        a = HH(a, b, c, d, x[k + 9], S31, 0xD9D4D039);
        d = HH(d, a, b, c, x[k + 12], S32, 0xE6DB99E5);
        c = HH(c, d, a, b, x[k + 15], S33, 0x1FA27CF8);
        b = HH(b, c, d, a, x[k + 2], S34, 0xC4AC5665);
        a = II(a, b, c, d, x[k + 0], S41, 0xF4292244);
        d = II(d, a, b, c, x[k + 7], S42, 0x432AFF97);
        c = II(c, d, a, b, x[k + 14], S43, 0xAB9423A7);
        b = II(b, c, d, a, x[k + 5], S44, 0xFC93A039);
        a = II(a, b, c, d, x[k + 12], S41, 0x655B59C3);
        d = II(d, a, b, c, x[k + 3], S42, 0x8F0CCC92);
        c = II(c, d, a, b, x[k + 10], S43, 0xFFEFF47D);
        b = II(b, c, d, a, x[k + 1], S44, 0x85845DD1);
        a = II(a, b, c, d, x[k + 8], S41, 0x6FA87E4F);
        d = II(d, a, b, c, x[k + 15], S42, 0xFE2CE6E0);
        c = II(c, d, a, b, x[k + 6], S43, 0xA3014314);
        b = II(b, c, d, a, x[k + 13], S44, 0x4E0811A1);
        a = II(a, b, c, d, x[k + 4], S41, 0xF7537E82);
        d = II(d, a, b, c, x[k + 11], S42, 0xBD3AF235);
        c = II(c, d, a, b, x[k + 2], S43, 0x2AD7D2BB);
        b = II(b, c, d, a, x[k + 9], S44, 0xEB86D391);
        a = AddUnsigned(a, AA);
        b = AddUnsigned(b, BB);
        c = AddUnsigned(c, CC);
        d = AddUnsigned(d, DD);
      }

      var temp = WordToHex(a) + WordToHex(b) + WordToHex(c) + WordToHex(d);

      return temp.toLowerCase();
    },

    runKonami() {
      var keys = []
      var konami = '38,38,40,40,37,39,37,39,66,65'
      var _this = this

      window.addEventListener('keydown', function (e) {
        keys.push(e.keyCode)
        if (keys.toString().indexOf(konami) >= 0) {
          keys = []
          _this.isKcModalOpen = true
        }
      }, true)
    }
  }
}
</script>

<style lang="scss">
.signup-text {
  margin-top: 20px;
  font-weight: 500;
  color: #000000;
  text-align: center;

  a {
    color: #D80000;

    &:hover {
      color: #000000;
    }
  }
}

.login-signup-wrapper {
  position: relative;
  height: 400px;
  overflow-x: hidden;

  div {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;

    transition: all 1s ease;
  }

  &.login {
    .login {
      opacity: 1;
      pointer-events: auto;
    }

    .signup {
      opacity: 0;
      pointer-events: none;
    }
  }

  &.signup {
    .signup {
      opacity: 1;
      pointer-events: auto;
    }

    .login {
      opacity: 0;
      pointer-events: none;
    }
  }
}

.modal-wrapper {
  &.kc {
    .modal {
      transform: scale(1.3);

      p {
        margin-bottom: 0 !important;
      }

      .content {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        background: url('@/assets/kc.png');
        background-size: cover;
        background-repeat: no-repeat;

        overflow: hidden;

        max-height: unset;

        // video {
        //   width: calc(100% - 30px);
        // }

        .video-resp {
          height: 220px;
          width: 400px;
          margin-top: 230px;
          pointer-events: none;
        }
      }
    }
  }

  .btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    a {
      margin: 15px;
      color: #000000;
      font-size: 18px;
      font-weight: bold;

      &:hover {
        transform: scale(1.05);
      }
    }
  }
}

.desk-only {
  display: block;

  @media screen and (max-width: 1024px) {
    border: 10px dashed blue;
    display: none;
  }
}

.mobile-only {
  display: none;

  @media screen and (max-width: 1024px) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    padding: 15px;

    h1 {
      color: #FFFFFF;
      width: 100%;
      max-width: 500px;
      text-align: center;
      font-size: 24px;
    }
  }
}
</style>