import Vue from 'vue'
import App from './App.vue'
import router from './router'

import './main.scss'

import VueMask from 'v-mask'
Vue.use(VueMask)

import VueCookies from 'vue-cookies'
Vue.use(VueCookies, { expires: '7d'})

Vue.config.productionTip = false

import VueGtag from 'vue-gtag'
Vue.use(VueGtag, { config: { id: 'G-VSTZW8XB2W', params: { send_page_view: false } } }, router)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
