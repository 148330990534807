<template>
  <footer>
    <div class="top">
      
    </div>

    <div class="middle">
      <div class="container">
        <p>
          Realização
        </p>

        <div class="logos">
          <a href="https://www.youtube.com/@PodcastTesouroDaMamae" target="_blank">
            <img src="@/assets/logo-tesouro-da-mamae.png" alt="">
          </a>

          <a href="https://www.youtube.com/@targasbr" target="_blank">
            <img src="@/assets/logo-targasbr.png" alt="">
          </a>

          <!-- <a href="https://discord.gg/csotbr" target="_blank">
            <img src="@/assets/logo-csotbr.png" alt="">
          </a> -->
        </div>
      </div>
    </div>

    <div class="bottom">
      <!-- <div class="container">
        <a href="https://discord.com/channels/806147920369287179/1146277223054196776" target="_blank" class="btn suporte">
          <p>
            Contactar suporte
          </p>
        </a>
      </div> -->

      <div class="container">
        <div class="col">
          <p>
            &copy;As Marés de Segredos - {{ new Date().getFullYear() }}
          </p>
        </div>

        <div class="col">
          <p>
            Este é um projeto realizado e idealizado por fãs, criadores de conteúdo e representantes da comunidade oficial Brasileira de Sea of Thieves. 
          </p>

          <p>
            Não possuímos nenhum vinculo com a Rare ou Microsoft.
          </p>
        </div>

        <div class="col">
          <p>
            Propriedade intelectual pertencente á Monkey Pirate Produções e Associados.
          </p>

          <img src="@/assets/logo-monkey-pirate.svg" alt="">
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'FooterVue',
}
</script>

<style scoped lang="scss">
footer {
  .top {
    background-image: url('@/assets/banner-footer.png');
    background-position: top center;
    background-size: cover;

    width: 100%;
    min-height: 400px;
  }
  .middle {
    background-image: url('@/assets/footer-middle.svg');
    background-position: top center;
    background-size: cover;

    margin-top: -50px;
    padding-top: 45px;
    padding-bottom: 100px;

    .container {
      flex-direction: column;
      align-items: center;
      justify-content: center;

      p {
        font-size: 12px;
        margin-bottom: 45px;
      }

      .logos {
        width: 550px;

        display: flex;
        align-items: center;
        justify-content: space-around;

        a {
          height: 150px;

          &:hover {
            transform: scale(1.1) rotate(1deg);
          }
        }
      }
    }
  }

  .bottom {
    background-image: url('@/assets/footer-bottom.svg');
    background-position: top center;
    background-size: cover;

    margin-top: -50px;
    padding: 45px 0;

    display: flex;
    flex-direction: column;

    .container {
      align-items: center;
      justify-content: center;

      .suporte {
        background-size: cover;
        transform: translateY(-75px);

        &:hover {
          transform: translateY(-75px) scale(1.1) rotate(1deg);
        }
      }

      .col {
        width: calc(100% / 3);

        p {
          margin-bottom: 0;
        }

        &:first-child {
          p {
            font-size: 12px;
          } 
        }

        &:nth-child(2) {
          p {
            text-align: center;
            font-size: 10px;
            margin-bottom: 15px;

            &:first-child {
              margin-bottom: 15px;
            }
          }
        }

        &:last-child {
          display: flex;
          align-items: center;
          justify-content: center;

          p {
            text-align: right;
            font-size: 13px;
          }

          img {
            margin-left: 15px;
          }
        }
      }
    }
  }
}
</style>
